import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
// import mock from '../mock/index'

import { Button, Form, Field, Checkbox, CheckboxGroup, Tab, Tabs, Toast, Dialog, Image as VanImage, Icon } from 'vant'
import { Cell, CellGroup } from 'vant'
import { Uploader } from 'vant'
import { Swipe, SwipeItem } from 'vant'
import { Tabbar, TabbarItem } from 'vant'
import VuewechatTitle from 'vue-wechat-title'
import { Divider } from 'vant'
import { List } from 'vant'
import { DatetimePicker } from 'vant'
import { Popup } from 'vant'
import { RadioGroup, Radio } from 'vant'
import { Sticky } from 'vant'
import { Empty } from 'vant'
import { Tag } from 'vant'

Vue.use(Tag)
Vue.use(Empty)
Vue.use(Sticky)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Popup)
Vue.use(DatetimePicker)
Vue.use(Divider)
Vue.use(VuewechatTitle)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Uploader)
Vue.use(Cell)
Vue.use(CellGroup)
// Vue.use(mock)
Vue.use(Icon)
Vue.use(Dialog)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.use(Toast)
Vue.use(VanImage)
Vue.use(List)

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

// 引入的多的话
// import { Button, Row, Col } from 'vant'
// Vue.use(Button).use(Row).use(Col)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
