const qiniu = require('qiniu-js')

const CONFIG = {
  useCdnDomain: true,
  // 上传域名区域
  region: qiniu.region.z2
}
const state = {
  upload_token: '',
  tokenTime: 0,

  baseSrc: 'http://cdn.dreamacro.com/'
}

const mutations = {
  SET_UPLOAD_TOKEN(state, val) {
    state.upload_token = val
  },

  SET_UPLOAD_TOKEN_TIME(state, val) {
    console.log(val, '获取tokan时间')
    state.tokenTime = val
  }
}

const actions = {
  updateFile({ state }, fileData) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
      // 原文件，原文件，唯一id
      // putExtra
      const putExtra = {
        fname: fileData.file.name, // 文件原始文件名
        mimeType: null // 对上传文件不做限制
      }

      //  上传创建
      const observable = qiniu.upload(
        fileData.file,
        process.env.NODE_ENV + '/' + fileData.file.lastModified,
        state.upload_token,
        putExtra,
        CONFIG
      )
      // 上传开始
      observable.subscribe({
        next: result => {
          console.log(result, '上传中')
        },
        error: err => {
          console.log(err)
        },
        complete: res => {
          console.log('asaa', res)
          const data = res

          // data.key = 'jmcy_' + uid

          resolve(data)
        }
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
