import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 路由配置表
const routes = [
  {
    path: '/',
    redirect: '/votemanagement'
  },
  {
    path: '/votemanagement',
    name: 'Votemanagement',
    component: () => import('@/views/votemanagement/index'),
    meta: {
      title: '投票管理'
    }
  },
  {
    path: '/votesign',
    name: 'Votesign',
    component: () => import('@/views/votesign/index'),
    meta: {
      title: '报名管理'
    }
  },
  {
    path: '/votepage',
    name: 'Votepage',
    component: () => import('@/views/votepage/index'),
    meta: {
      title: '详情管理'
    }
  },
  {
    path: '/sharevotepage',
    name: 'Sharevotepage',
    component: () => import('@/views/sharevotepage/index'),
    meta: {
      title: '投票分享'
    }
  },
  {
    path: '/voteset',
    name: 'Voteset',
    component: () => import('@/views/voteset/index'),
    meta: {
      title: '投票设置'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/publicvote/',
  routes
})
router.beforeEach((to, from, next) => {
  if ((to.meta.title)) {
    document.title = to.meta.title
  }
  next()
})

export default router
